import React, { useEffect, useState } from 'react';
import './App.scss';
import { Route, Routes, useNavigate } from 'react-router';
import Login from './pages/Login/Login';
import Menu from './pages/Menu/Menu';
import NewRecepisse from './pages/NewRecepisse/NewRecepisse';
import RecepisseDetails from './pages/RecepisseDetails/RecepisseDetails';
import { useRecoilState } from 'recoil';
import { RecoilloadState, UserInfoStateRecoil } from './utilities/globaleState';
import { isObjectEmpty } from './utilities/helpers';
import API from './utilities/API';
import NavBar from './components/NavBar/NavBar';
import Liste from './pages/Liste/Liste';
function App() {
  const [userInfoState,setUserInfoState] = useRecoilState(UserInfoStateRecoil);
  const [ isLoading,setIsLoading ] = useRecoilState(RecoilloadState);
  
  const navigate = useNavigate();
  const api = new API();
  useEffect(() => {
     if( isObjectEmpty(userInfoState) && localStorage.getItem("token")){
         setIsLoading(true);
           api.apiData("get","user/currentuser").then((resp) => {
                if( resp?.status !== 200 ){
                  localStorage.removeItem("token");navigate('/');
                }
                else{
                   setUserInfoState(resp?.user)
                } 
           });
         setIsLoading(false);
     }
     else if( !localStorage.getItem("token") )
       navigate('/');
  },[]);
  return (
    <div className="App">
       {!isObjectEmpty(userInfoState) && <NavBar />}
       <Routes>
          <Route path='/' element={<Login />}/>
          <Route path='/newrecepisse' element={<NewRecepisse />} />
          <Route path='/recepissedetails/:id' element={<RecepisseDetails />}/>
          <Route path='/liste' element={<Liste />}/>
       </Routes>
    </div>
  );
}

export default App;
