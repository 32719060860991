import React, { useEffect, useState } from 'react'
import { DataGrid, GridColDef, GridToolbar,frFR } from '@mui/x-data-grid';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import moment from 'moment';
import "./ListComponent.scss";
function ListeComponent({ table }:any) {
  // console.log(table)
  const [ paiement,setPaiement ] = useState<[]>([]);
  const columns : GridColDef[] = [
     {
        field: "ordre",
        headerName: "N°Ordre",
        maxWidth: 80
     },
     {
        field:'chassis',
        headerName: "N°Chassis",
        minWidth:120,
     },
     {
        field:"paiementNumber",
        headerName: "N°Paiement",
        minWidth: 180
     },
     {
      field:"fullname",
      headerName: "Fait par",
      minWidth: 180
     },
     {
        field:"created_at",
        headerName: "Date",
        minWidth: 180
     },
     {
        field: "options",
        headerName: "Options",
        sortable: false,
      
        flex: 1,
        minWidth: 150,
        renderCell: (params) => {
          const id = params.id;
          return (
            <div className="options">
              <Link to={`/recepissedetails/${params.row.paiementNumber}`}>
                <button>Voir</button>
              </Link>
            </div>
          );
        },
     }
  ];

  React.useEffect(() => {
    setPaiement(table?.map((item:any,index:number) => {
        return{
          ordre:index + 1,
          id:  item?.id,
          chassis: item?.chassis,
          paiementNumber: item?.paiementNumber,
          fullname: item?.user[0].prenom + " " + item?.user[0].nom,
          created_at: moment(item?.created_at).format("DD/MMMM/YYYY")  
        }
    }))
  },[table]);

  return (
    <div className='liste__table'>
      <Box sx={{ height: '580px', width: '100%' }}>
          <DataGrid
             components={{ Toolbar: GridToolbar }}
             rows={paiement}
             columns={columns}
             autoPageSize
             localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
            />
      </Box>
    </div>
  )
}

export default ListeComponent