import React, { useState } from 'react';
import "./NewRecepisse.scss";
import Footer from '../../components/Footer/Footer';
import { useNavigate } from 'react-router';
import { useRecoilState, useRecoilValue } from 'recoil';
import { RecoilloadState, UserInfoStateRecoil } from '../../utilities/globaleState';
import { useForm ,SubmitHandler } from 'react-hook-form';
import API from '../../utilities/API';
import Erreurs from '../../components/Erreurs/Erreurs';
import Swal from 'sweetalert2';
interface Recepisse {
  paiementNumber: string;
}

function NewRecepisse() {
  const navigate = useNavigate();  

  // const [ userInfoState , setUserInfoState ] = useRecoilState(UserInfoStateRecoil);
  const user = useRecoilValue(UserInfoStateRecoil);
  const [recepisseInfo,setRecepisseInfo ] = useState<Recepisse >({paiementNumber:''});
  const [ erreurs,setErreurs ] = useState<[]>([])
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<Recepisse>();

  const api = new API();
  const [ isLoading,setIsLoading ] = useRecoilState(RecoilloadState);

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRecepisseInfo({...recepisseInfo,[e.target.name] : e.target.value?.toUpperCase()})
   }
   const handleSubmitForm :SubmitHandler<Recepisse> = async (data) => {
    setErreurs([]);setIsLoading(true);
    const resp =  await api.apiData("get",`recepisse/isPrinted/${data?.paiementNumber}`);
    if( resp?.recepisseInfo ){
       Swal.fire({
            title: "Êtes-vous sûr?",
            text: "Ce récépissé a été déjà imprimé.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: `Oui`,
            cancelButtonText: "Non",
        }).then((result) => {
        
            if (result.isConfirmed) {
             
            api.apiData("post","recepisse/nouvelle",data).then((resp) => {
              if( resp.status !== 200 ){
                setErreurs(resp.errors);
                setIsLoading(false);  
              }
              else{
                navigate(`/recepissedetails/${resp.RecepInfo.paiementNumber}`)
                setIsLoading(false);  
              }
            })
           
            }})
     } else{
        const resp1 = await api.apiData("post","recepisse/nouvelle",data);
        setIsLoading(false);
        if( resp1.status !== 200 ){
            setErreurs(resp1.errors);
         }
        else{
          navigate(`/recepissedetails/${resp1.RecepInfo.paiementNumber}`)
        }
     }
    
   
   }  
  return (
    <div className='recepisse'>
      <form onSubmit={handleSubmit(handleSubmitForm)}>
       <Erreurs validation={erreurs} />
        <div className='recepisse__input-group'>
            <div className='recepisse__input-group__label'>
                N°Paiement
            </div>
            <div className='recepisse__input-group__input-value'>
                <input type='text' placeholder='Saisir le numéro de paiement'
                 value = {recepisseInfo?.paiementNumber}  
                 {...register('paiementNumber', {
                  onChange: (e) => {
                       handleInput(e)
                  },
                required:true,minLength:3,maxLength:30},
              )}
                />
            </div>
            {errors.paiementNumber && errors.paiementNumber?.type === "required" && (
            <span className="error-msg">Le numero de paiement est obligatoire.</span>
            )}
            {errors.paiementNumber && errors.paiementNumber?.type === "minLength" && (
              <span className="error-msg">Le minimum de caractère est trois (3).</span>
            )}
            {errors.paiementNumber && errors.paiementNumber?.type === "maxLength" && (
              <span className="error-msg">Le caractère maximum est trente (30).</span>
            )}
        </div>
        <div className='recepisse__button'>
            <button type='submit' className={`${isLoading? 'no-pointer':''}`} disabled={isLoading}>{!isLoading ? "Valider":"Patientez.."}</button>
        </div>
       </form> 
       <Footer />   
    </div>
  )
}

export default NewRecepisse