import React from 'react'
import "./NavBar.scss";
import { useNavigate } from 'react-router';
import { useRecoilState } from 'recoil';
import { UserInfoStateRecoil } from '../../utilities/globaleState';
import { NavLink } from 'react-router-dom';
function NavBar() {
 const navigate = useNavigate();   
 const [userInfoState,setUserInfoState] = useRecoilState(UserInfoStateRecoil);
 const logout = () => {
    localStorage.removeItem('token');
    setUserInfoState({});
    navigate("/");
 }   
  return (
    <div className='NavBar'>
        <div className="NavBar__elements">
           <ul>
              <li>
                <button onClick={() => logout()}>Deconnecter</button>
              </li>
              
              <li>
                  <NavLink to="/liste" className={(navData) => navData.isActive ? "link-active" : ""}>Liste des Imprimés</NavLink>
              </li>
              <li>
                 <NavLink to="/newrecepisse" className={(navData) => navData.isActive ? "link-active" : ""}>Nouveau</NavLink>
              </li>
           </ul>
        </div>
    </div>
  )
}

export default NavBar