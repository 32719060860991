import React, { useEffect, useRef, useState } from "react";
import "./RecepisseDetails.scss";
import armoirie from "../../assets/images/logo/armoiries.png";
import datamatrix from "../../assets/images/logo/datamatrix.png";
import branding from "../../assets/images/logo/branding.png";
import { useReactToPrint } from "react-to-print";
import { useNavigate, useParams } from "react-router";
import { string } from "zod";
import API from "../../utilities/API";
import { useRecoilState } from "recoil";
import { RecoilloadState } from "../../utilities/globaleState";
import moment from "moment";
function RecepisseDetails() {
  const printRef = useRef<any>();
  const { id } = useParams();

  const printCertificat = useReactToPrint({
    content: () => printRef.current,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [paiementInfo,setPaiementInfo ] = useState<any>('');
  const [ recepisseInfo, setRecepisseInfo ] = useState<any>('');
  const api = new API();
  const navigate = useNavigate();
  const getPaiementInfo = async (id: string | undefined) => {
    setIsLoading(true)
        const resp = await api.apiData("get", `recepisse/isPrinted/${id}`);
       
        if( resp.status === 200 ){
           setPaiementInfo(resp.paiementInfo);
           setRecepisseInfo(resp.recepisseInfo)
        }
     setIsLoading(false);
  
  };
  useEffect(() => {
    getPaiementInfo(id);
  }, [id]);

  return (
    <div className="recepisse-details">
      
      {(isLoading ) ? (
        <div>Patientez.........</div>
      ) : (
        <>
        
        {typeof paiementInfo !== 'undefined' ?
          <>
              <div className="recepisse-details__button" >
              <button onClick={() => navigate(-1)}>Retour</button>
              <button onClick={printCertificat}>Imprimer</button>
            
            </div>
            <div className="recepisse-details__container" ref={printRef}>
              <div className="recepisse-details__header-1">
                <div className="recepisse-details__header-1__left">
                  <div className="title-1">
                    Ministere de la securité et de la protection civile
                  </div>
                  <div className="title-2">
                    Direction Generale de la police nationale
                  </div>
                </div>
                <div className="recepisse-details__header-1__center">
                  <img src={"http://portieres.dsdguinee.com/armoiries.png"} alt="logo-police" />
                </div>
                <div className="recepisse-details__header-1__right">
                  <div className="title-1">Republique de Guinee</div>
                  <div className="title-2">
                    <span>Travail-</span>
                    <span>Justice-</span>
                    <span>Solidarite</span>
                  </div>
                </div>
              </div>
              <div className="recepisse-details__header-2">
                <div className="title-1">
                  Direction Centrale de la sécurité routière
                </div>
              </div>
              <div className="recepisse-details__line"></div>
              <div className="recepisse-details__header-3">
                <div className="title-1">
                  Recepissé de dépot de dossier tenant lieu de numéro de portières
                </div>
                <div className="title-2">valable jusqu'au {moment(recepisseInfo.created_at).add(3,'month').format("DD/MM/YYYY")}</div>
              </div>

              {/* les contenues */}
              <div className="recepisse-details__values">
                <div className="recepisse-details__values__reference">
                  <div className="recepisse-details__values__reference__label">
                    Réference de Payment
                  </div>
                  <div className="recepisse-details__values__reference__label__values">
                    {recepisseInfo.paiementNumber}
                  </div>
                </div>

                <div className="recepisse-details__values__immatriculation">
                  <div className="recepisse-details__values__immatriculation__number">
                    <div className="recepisse-details__values__immatriculation__number__label">
                      N° d'immatriculation
                    </div>
                    <div className="recepisse-details__values__immatriculation__number__values">
                    {paiementInfo.plaque}
                    </div>
                  </div>
                  <div className="recepisse-details__values__immatriculation__type-autorisation">
                    <div className="recepisse-details__values__immatriculation__type-autorisation__label">
                      Type d'autorisation
                    </div>
                    <div className="recepisse-details__values__immatriculation__type-autorisation__values">
                      {paiementInfo.autorisation}
                    </div>
                  </div>
                </div>

                <div className="recepisse-details__values__proprietaire">
                  <div className="recepisse-details__values__proprietaire__title-1">
                    <div>Information du Propriétaire</div>
                    <div></div>
                  </div>
                  <div className="recepisse-details__values__proprietaire__info">
                    <div className="recepisse-details__values__proprietaire__info__nom">
                      <div className="recepisse-details__values__proprietaire__info__nom__label">
                        Nom / DENOMINATION
                      </div>
                      <div className="recepisse-details__values__proprietaire__info__nom__values">
                        {paiementInfo.client}
                      </div>
                    </div>

                    <div className="recepisse-details__values__proprietaire__info__adresse">
                      <div className="recepisse-details__values__proprietaire__info__adresse__label">
                        Adresse
                      </div>
                      <div className="recepisse-details__values__proprietaire__info__adresse__values">
                        ----------
                      </div>
                    </div>

                    <div className="recepisse-details__values__proprietaire__info__adresse">
                      <div className="recepisse-details__values__proprietaire__info__adresse__label">
                        Date de Naissance
                      </div>
                      <div className="recepisse-details__values__proprietaire__info__adresse__values">
                      {  moment(paiementInfo.dateNaissance).format('DD/MM/YYYY')}
                      </div>
                    </div>

                    <div className="recepisse-details__values__proprietaire__info__adresse">
                      <div className="recepisse-details__values__proprietaire__info__adresse__label">
                        Profession
                      </div>
                      <div className="recepisse-details__values__proprietaire__info__adresse__values">
                        {paiementInfo.profession}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="recepisse-details__values__info-vehicule">
                  <div className="recepisse-details__values__info-vehicule__title-1">
                    <div>Infos Vehicule</div>
                    <div></div>
                  </div>
                  <div className="recepisse-details__values__info-vehicule__info">
                    <div className="recepisse-details__values__info-vehicule__info__nom">
                      <div className="recepisse-details__values__info-vehicule__info__nom__label">
                        Marque
                      </div>
                      <div className="recepisse-details__values__info-vehicule__info__nom__values">
                        {paiementInfo.marque}
                      </div>
                    </div>
                    <div className="recepisse-details__values__info-vehicule__info__nom">
                      <div className="recepisse-details__values__info-vehicule__info__nom__label">
                        N°Chassis
                      </div>
                      <div className="recepisse-details__values__info-vehicule__info__nom__values chassis">
                        {paiementInfo.chassis}
                      </div>
                    </div>
                  </div>

                  <div className="recepisse-details__values__info-vehicule__info">
                    <div className="recepisse-details__values__info-vehicule__info__nom">
                      <div className="recepisse-details__values__info-vehicule__info__nom__label">
                        Modele
                      </div>
                      <div className="recepisse-details__values__info-vehicule__info__nom__values vehicule">
                        {paiementInfo.model}
                      </div>
                    </div>
                    <div className="recepisse-details__values__info-vehicule__info__nom">
                      <div className="recepisse-details__values__info-vehicule__info__nom__label">
                        Puissance Administrative
                      </div>
                      <div className="recepisse-details__values__info-vehicule__info__nom__values chassis">
                        {paiementInfo.puissance} cv
                      </div>
                    </div>
                  </div>

                  <div className="recepisse-details__values__info-vehicule__info">
                    <div className="recepisse-details__values__info-vehicule__info__nom">
                      <div className="recepisse-details__values__info-vehicule__info__nom__label">
                        Source d'energie
                      </div>
                      <div className="recepisse-details__values__info-vehicule__info__nom__values vehicule">
                        {paiementInfo.energie}
                      </div>
                    </div>
                    <div className="recepisse-details__values__info-vehicule__info__nom">
                      <div className="recepisse-details__values__info-vehicule__info__nom__label">
                        Carrosserie
                      </div>
                      <div className="recepisse-details__values__info-vehicule__info__nom__values chassis">
                        {paiementInfo.carrosserie}
                      </div>
                    </div>
                  </div>

                  <div className="recepisse-details__values__info-vehicule__info">
                    <div className="recepisse-details__values__info-vehicule__info__nom">
                      <div className="recepisse-details__values__info-vehicule__info__nom__label">
                        Type
                      </div>
                      <div className="recepisse-details__values__info-vehicule__info__nom__values vehicule">
                      {paiementInfo.genre}
                      </div>
                    </div>
                    <div className="recepisse-details__values__info-vehicule__info__nom">
                      <div className="recepisse-details__values__info-vehicule__info__nom__label">
                        Genre
                      </div>
                      <div className="recepisse-details__values__info-vehicule__info__nom__values chassis">
                        {paiementInfo.typeVehicule}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* directeurs  */}
              <div className="recepisse-details__signataire">
                <div className="recepisse-details__signataire__date">
                  <div className="recepisse-details__signataire__date__label">
                    Date Effet
                  </div>
                  <div className="recepisse-details__signataire__date__value">
                  {moment().format("DD/MM/YYYY")}
                  </div>
                </div>
                <div className="recepisse-details__signataire__nom">
                  <div className="recepisse-details__signataire__nom__label">
                    Chef de Service
                  </div>
                  <div className="recepisse-details__signataire__nom__value"></div>
                </div>
              </div>

              {/* pied de page */}
              <div className="recepisse-details__pied">
                <div className="recepisse-details__pied__matrix">
                  <img src={process.env.REACT_APP_URL?.replace("/api","") + recepisseInfo.qrcodePath} alt="data matrix" />
                </div>
                <div className="recepisse-details__pied__branding">
                  <img src={branding} alt="branding national" />
                </div>
              </div>
            </div>
           </>  : <p>Réçu non trouvé</p>
          }
        
        </>
      )}
    </div>
  );
}

export default RecepisseDetails;
