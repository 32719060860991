import React, { useEffect, useState } from 'react';
import "./Liste.scss";
import ListeComponent from '../../components/Liste/ListeComponent';
import { useRecoilState } from 'recoil';
import { RecoilloadState } from '../../utilities/globaleState';
import API from '../../utilities/API';

function Liste() {
  const [ isLoading,setIsLoading ]  = React.useState<boolean>(false);
  const [ data, setData ] = React.useState<[]>([]);
  const api = new API();
  const getListeImpression = async () => {
       setIsLoading(true);
         const resp = await api.apiData("get","recepisse/getAllImpression");
     
         if( resp.status === 200 )
            setData(resp.liste);

      setIsLoading(false)   
  }
  useEffect(() => {
     getListeImpression();
  },[])
  return (
    <div className='liste'>
        <div className='liste__header'>
             <h3>Liste des Récépissé Imprimés</h3>
             {!isLoading ?
             <ListeComponent table={data}/> :
              <p>Chargement...</p>
             }
        </div>
    </div>
  )
}

export default Liste