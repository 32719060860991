import React, { useState } from 'react'
import "./login.scss";
import logo from "../../assets/images/logo/logo-dsd.png";
import Footer from '../../components/Footer/Footer';
import { useNavigate } from 'react-router';
import { useForm,SubmitHandler } from 'react-hook-form';

import { z } from 'zod';
import { useRecoilState } from 'recoil';
import { RecoilloadState, UserInfoStateRecoil } from '../../utilities/globaleState';
import API from '../../utilities/API';
import Erreurs from '../../components/Erreurs/Erreurs';

interface UserInput {
   username:string;
   password:string,
 }
const schema = z.object({
   username: z.string({
      required_error: "Le nom d'utilisateur est obligatoire.",
      invalid_type_error: "Le nom d'utilisateur doit être de type chaine de caractère.",
   }).min(2,{message: "Le caractère minimum est deux."}),
   password: z.string()
});

function Login() {
  const navigate = useNavigate();
  const [userInfo,setUserInfo ] = useState<UserInput>({username:'',password:''});
  const {
   register,
   handleSubmit,
   watch,
   formState: { errors },
 } = useForm<UserInput>();
 const [ userInfoState , setUserInfoState ] = useRecoilState(UserInfoStateRecoil);
 const [isLoading,setIsLoading ] = useRecoilState(RecoilloadState);
 const [ erreurs,setErreurs ] = useState<[]>([])
 const api = new API();

  const Authentify :SubmitHandler<UserInput> = async (data) => {
  
     setIsLoading(true);setErreurs([]);
       var formData = new FormData();
       formData.append('username',data.username);
       formData.append('password',data.password);
       const rep = await api.auth(formData);
      
       if( rep.status !== 200 ){
          setErreurs(rep.errors);
       }else {
         const user = rep.user.user;
         //delete user._id;
         setUserInfoState(user);
         navigate('/newrecepisse')
       }
     setIsLoading(false);
    
  }  
//   console.log(userInfoState)
  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
     setUserInfo({...userInfo,[e.target.name] : e.target.value})
  }

  return (
    <div className='login'>
        <div className='login__form'>
            <form onSubmit={handleSubmit(Authentify)}>
                 <div className='login__form__heading'>
                    <div className='logo'>
                      <img src={logo} alt='logo-DSD' />
                    </div>
                    <div className='title'>
                       <h3>Connexion</h3>
                    </div>
                 </div>
                 <div className='login__form__body'>
                  <Erreurs validation={erreurs} />
                    <div className='input-group'>
                       <div>
                          <div className='label'>
                               Utilisateur
                          </div>
                          <div className='div-input'>
                             <input type='text' placeholder='Utilisateur'    
                              {...register('username', {
                                 onChange: (e) => {
                                      handleInput(e)
                                 },
                               required:true,minLength:3,maxLength:30},
                             )}
                             />

                          </div>
                          {errors.username && errors.username?.type === "required" && (
                            <span className="error-msg">Le Nom d'utilisateur est obligatoire.</span>
                          )}
                           {errors.username && errors.username?.type === "minLength" && (
                              <span className="error-msg">Le minimum de caractère est trois (3).</span>
                           )}
                           {errors.username && errors.username?.type === "maxLength" && (
                              <span className="error-msg">Le caractère maximum est trente (30).</span>
                           )}
                       </div>
                       <div>
                          <div className='label'>
                               Mot de Passe
                          </div>
                          <div className='div-input'>
                             <input type='password' placeholder='mot de passe' 
                               {...register('password', {
                                 onChange: (e) => {
                                      handleInput(e)
                                 },
                               required:true,},
                             )}
                             />
                          </div>
                          {errors.password && errors.password?.type === "required" && (
                             <span className="error-msg">Le mot de passe est obligatoire.</span>
                          )}
                       </div>
                    </div>
                    <div>
                        <button type='submit' className={`${isLoading?'no-pointer':''}`}  disabled={isLoading}> {!isLoading ?"Connecter": "Connecter..."}</button>
                    </div>
                 </div>
            </form>
        </div>
        <Footer />
    </div>
  )
}

export default Login