import React from 'react'
import "./Footer.scss";
function Footer() {
  return (
    <footer className='footer'>
        <p>Copyrigth@DSD-2023</p>   
    </footer>
  )
}

export default Footer